<template>
  <div>
    <conference-description v-if="servicesData.Conference !== 2"></conference-description>
    <base-service v-else>
      <template #title>
        <h2>Услуга «Конференц-связь»</h2>
      </template>
      <template #body>
        <div v-if="!isActiveRooms" class="info-block_color">
          В данный момент у вас нет активных аудиоконференций
        </div>
        <conference-active v-else></conference-active>
      </template>
    </base-service>
  </div>
</template>

<script>
import BaseService from '@/components/shared/BaseService.vue';
import ConferenceDescription from '@/components/services/conference_call/ConferenceDescription.vue';
import ConferenceActive from '@/components/services/conference_call/ConferenceActive.vue';
import { getServiceData } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      interval: ''
    };
  },

  components: {
    BaseService,
    ConferenceDescription,
    ConferenceActive
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  mounted() {
    // Проверяем подключена ли услуга
    if (this.servicesData.Conference === 2) {
      this.getCalls();
      this.checkCallsInterval();
    }
  },

  computed: {
    ...mapGetters({ servicesData: 'connectedServices/servicesData', calls: 'conference/calls' }),

    isActiveRooms() {
      return this.calls.Parties?.length > 0;
    }
  },

  methods: {
    ...mapActions({ showCalls: 'conference/showCalls' }),

    // Получает список конференций
    getCalls() {
      getServiceData('https://api.onlinelogic.ru/ConferenceService/GetConferenceServices')
        .then((result) => {
          if (result.Rooms) {
            this.showCalls(result);
          } else {
            this.showCalls('');
          }
        })
        .catch((e) => catchFunction(e));
    },

    // Интервал для отслеживания в режиме реального времени
    checkCallsInterval() {
      this.interval = setInterval(() => {
        this.getCalls();
      }, 4000);
    }
  }
};
</script>

<style></style>
