<template>
  <div>
    <base-table
      v-for="(number, roomNumber) in calls.Rooms"
      :key="roomNumber"
      :fields="fields"
      :items="calls.Parties"
    >
      <template #headerText> Конференция на номере {{ number }} </template>
      <template #additional>
        <p>
          Комната номер:
          <b> {{ roomNumber }} </b>
        </p>
      </template>
      <template #cell(PartyNumber)="{index}">
        {{ calls.Parties[index].PartyNumber }}
      </template>
      <template #cell(SetupTime)="{index}">
        {{ calls.Parties[index].SetupTime.replace(/[T]/, ' ') }}
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      fields: [
        { key: 'PartyNumber', label: 'Номер участника аудиоконференции' },
        { key: 'SetupTime', label: 'Дата и время подключения' }
      ]
    };
  },

  components: {
    BaseTable
  },

  computed: mapGetters({ calls: 'conference/calls' })
};
</script>

<style lang="scss" module>
.partyRow {
  padding: 0.25rem;
  border-bottom: 1px solid $asfalt;
}
</style>
