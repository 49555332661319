<template>
  <ServicesDescription :isPageWrapper="true" @connectService="connectService">
    <template #title>
      <h2>Конференц-связь</h2>
    </template>
    <template #body>
      <p>Соберите всех вместе с помощью услуги Аудиоконференция!</p>
      <p>
        <span>
          <b>
            Проводите совещания с коллегами
          </b>
        </span>
        <br />
        <span>
          Современные компании часто проводят рабочие совещания в формате конференций. Это особенно
          удобно, если филиалы и подразделения находятся в разных местах или сотрудники компании
          работают удаленно.
        </span>
      </p>
      <p>
        <span>
          <b>
            Участвуйте в переговорах с клиентами и партнерами
          </b>
        </span>
        <br />
        <span>
          Для удобства партнеров, клиентов и ваших сотрудников переговоры также можно проводить в
          режиме конференц-связи. Это не требует затрат на приобретение дополнительного оборудования
          и экономит время.
        </span>
      </p>
      <br />
      <p>Управлять конференцией легко через Личный кабинет:</p>
      <ul class="list_mark">
        <li>В режиме on-line можно видеть список участников конференции</li>
        <li>
          Отчёты о проведённых конференциях включают в себя дату, время и количество участников.
        </li>
      </ul>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  components: {
    ServicesDescription
  },

  methods: {
    connectService() {
      this.$router.push({
        path: '/create-statement',
        query: { st: 'addService', service: 'конференцсвязь' }
      });
    }
  }
};
</script>

<style></style>
